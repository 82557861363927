import {Component, ElementRef, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
import {AppComponent} from '../app.component';
import {Router} from '@angular/router';
import {timeout, timestamp} from 'rxjs/operators';

interface MobileTaskObject {
  workers: Array<string>;
  startTime: string;
  endTime: string;
  project: MobileProjectObject;
}

interface MobileProjectObject {
  id: number;
  name: string;
  color: string;
  description: string;
}


@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css']
})
export class MobileComponent implements OnInit {

  today;
  worker;
  day = 'Montag';
  data: Array<MobileTaskObject>;
  show = {};
  year;

  constructor(private app: AppComponent, private api: ApiService, private router: Router, private element: ElementRef) {
    this.api.getLogin().subscribe((data: {user: string, link: string, role: string}) => {
      if (data.user) {
        this.app.user = {user: data.user, role: data.role};
        this.worker = data.user;
        this.loadTasks();
        if (this.app.user.role === 'admin') {
          this.router.navigate(['/planner']);
        }
      } else {
        window.location.href = data.link;
      }
    });
  }

  ngOnInit(): void {
    this.today = new Date();
    this.year = this.today.getFullYear();
  }

  addDay(): void {
    this.today.setDate(this.today.getDate() + 1);
  }

  removeDay(): void {
    this.today.setDate(this.today.getDate() - 1);
  }

  loadTasks(): void {
    console.log(this.year);
    console.log(this.getWeek());
    console.log(this.today.toLocaleString('de-DE', { weekday: 'long' }));
    this.api.getMobileTasks(this.getWeek(), this.year, this.today.toLocaleString('de-DE', { weekday: 'long' }),
      this.worker).subscribe((data) => {
      this.data = data.tasks;
      setTimeout(() => {
        const elements = this.element.nativeElement.querySelectorAll('.description-mobile');
        for (const element of elements) {
          element.innerHTML = element.innerHTML.replace(/([+]?[0-9]+\s?[0-9]+)/, '<a href="tel:$1">$1</a>');
        }
      }, 100);
    });
  }

  getWeek(): number {
    const today = new Date(this.today);
    today.setDate(today.getDate() - (today.getDay() - 1));
    this.year = today.getFullYear();
    const firstDayOfYear = this.getFirstDay(today.getFullYear());
    const pastDaysOfYear = Math.floor((today.getTime() - firstDayOfYear.getTime()) / 86400000);
    return Math.ceil((pastDaysOfYear + (firstDayOfYear.getDay() + 1)) / 7);
  }

  getFirstDay(year): Date {
    const date = new Date(year, 0, 1);
    for (let i = 0; i < 7; i++) {
      if (date.getDay() === 4) {
        date.setDate(date.getDate() - 3);
        return date;
      }
      date.setDate(date.getDate() + 1);
    }
  }



}
