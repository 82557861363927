<div class="container">
  <table class="table">
    <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Name</th>
      <th class="text-center">Sortierung</th>
      <th class="text-center"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let worker of workers">
      <td><input [(ngModel)]="worker.id"></td>
      <td><input [(ngModel)]="worker.name"></td>
      <td class="text-center"><input [(ngModel)]="worker.sort"></td>
      <td class="text-center"><button class="btn btn-danger" (click)="deleteWorker(worker.id)">Löschen</button></td>
    </tr>
    </tbody>
  </table>
  <div class="float-right">
    <span class="text-success mr-2" [hidden]="!saved">gespeichert</span>
    <button class="btn btn-primary mr-2" data-toggle="modal" data-target="#workerCreate">Neuer User</button>
    <button class="btn btn-primary" (click)="setWorkers()">Speichern</button>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="workerCreate">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >Neuer Mitarbeiter</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form>
        <div class="modal-body">
          <div class="form-group">
            <label class="col-form-label">ID:</label>
            <input type="text" class="form-control" #workerId>
          </div>
          <div class="form-group">
            <label class="col-form-label">Name:</label>
            <input type="text" class="form-control" #workerName>
          </div>
          <div class="form-group">
            <label class="col-form-label">Sortierung:</label>
            <input type="text" class="form-control" #workerSort>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="workers.push({name: workerName.value, id: workerId.value, sort: workerSort.value})">Speichern</button>
        </div>
      </form>
    </div>
  </div>
</div>
