
<div class="container">
  <div class="week-selector row">
    <div class="col-3 text-right">
      <i class="fas fa-angle-left" (click)="removeDay(); loadTasks()"></i>
    </div>
    <div class="col-6">
      {{ today.toLocaleString('de-DE', {  weekday: 'short',  month: 'short',  day: 'numeric' }) }}
    </div>
    <div class="col-3 text-left">
      <i class="fas fa-angle-right" (click)="addDay(); loadTasks()"></i>
    </div>
  </div>
  <div class="card m-2 text-center project" *ngFor="let task of data"
       [ngStyle]="{'border-left': '10px solid ' + task.project.color}" [id]="task.startTime">
    {{ task.project.name }}<br>
    <span *ngIf="task.startTime !== task.endTime">{{ task.startTime }} bis {{ task.endTime }}</span>
    <span *ngIf="task.startTime === task.endTime">{{ task.startTime }}</span>
    <span>{{ task.workers }}</span>
    <button class="description-toggle" (click)="show[task.startTime] = !show[task.startTime]" [hidden]="task.project.description === ''">
      <i class="fas fa-angle-down" [hidden]="!show[task.startTime]"></i><i class="fas fa-angle-left" [hidden]="show[task.startTime]"></i>
    </button>
    <div [hidden]="!show[task.startTime]">
      <div class="card-body description-mobile">
        {{ task.project.description }}
      </div>
    </div>
  </div>

</div>
