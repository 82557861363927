<header [class.zoom]="showMode">
  <div class="week-selector"><i
    class="fas" style="width: 40px; text-align: center" [class.fa-eye]="!showMode" [class.fa-eye-slash]="showMode"
    (click)="showMode = !showMode"></i></div>
  <div class="week-selector"><i class="fas fa-angle-left" (click)="week = week-1; loadTasks()"></i> KW: {{ week }} <i
    class="fas fa-angle-right" (click)="week = week+1; loadTasks()"></i></div>
  <div class="week-selector" style="font-size: 20px">{{ weekString }}</div>
</header>
<div class="container-fluid" *ngIf="data" [class.zoom]="showMode">
  <div class="row">
    <div [class.col-10]="!showMode" [class.col-12]="showMode" class="days">
      <div class="card mt-3" *ngFor="let day of data.days; let dayIndex = index">
        <div class="card-body tasks">
          <h1>{{ day.name }}</h1>
          <table class="table" cellspacing="0">
            <thead>
            <tr>
              <th scope="col">Mitarbeiter</th>
              <th scope="col" *ngFor="let time of day.head">{{ time }}</th>
              <th class="text-center">Info</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let worker of day.workers">
              <th scope="row" (click)="setTasks(day, worker.id, dayIndex)">{{ worker.name }}</th>
              <td *ngFor="let task of worker.tasks" [bgColor]="task.projectColor"
                  [colSpan]="task.length.length">{{ task.projectName }}
                <div class="click-tracker row">
                  <div class="col" *ngFor="let time of task.length"
                       (click)="setTask(day, worker.id, dayIndex, time)"></div>
                </div>
              </td>
              <td class="text-center info-input" data-toggle="modal" [attr.data-target]="'#info' + worker.id + day.name"
                  [class.green]="worker.info !== ''"><i class="fas fa-info-circle"></i></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-2" *ngIf="!showMode">
      <div class="card mt-3 projects-card">
        <div class="card-body projects">
          <div class="card mb-2 project" [ngStyle]="{'border-left': '10px solid ' + 'red'}"
               [class.selected]="selectedProject === 0" (click)="selectProject(0)">
            löschen
          </div>
          <hr>
          <div class="card mb-2 project" *ngFor="let project of projects; let projectIndex = index"
               [hidden]="!project.fixed || collapseProjects" [ngStyle]="{'border-left': '10px solid ' + project.color}"
               [class.selected]="project.selected" (click)="selectProject(projectIndex + 1)">
            {{ project.name }}
          </div>
          <button class="btn btn-light projects-toggle" (click)="collapseProjects = !collapseProjects"><i
            class="fas fa-angle-down"
            [hidden]="!collapseProjects"></i><i
            class="fas fa-angle-up" [hidden]="collapseProjects"></i></button>
          <hr>
          <div class="card mb-2 project" *ngFor="let project of projects; let projectIndex = index"
               [hidden]="project.fixed" [ngStyle]="{'border-left': '10px solid ' + project.color}"
               [class.selected]="project.selected" (click)="selectProject(projectIndex + 1)">
            {{ project.name }}
            <div class="project-info-button" data-toggle="modal" [attr.data-target]="'#projectEdit' + project.id"><i
              class="fas fa-info-circle"></i></div>
            <div class="project-remove-button" (click)="removeProject(project.id)"><i class="fas fa-times-circle"></i>
            </div>
          </div>
          <hr>
          <button class="btn btn-primary w-100" data-toggle="modal" data-target="#projectCreate">Neues Projekt</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="projectCreate" tabindex="-1" role="dialog" aria-labelledby="projectCreateLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="projectCreateLabel">Neues Projekt</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form #createProjectForm>
        <div class="modal-body">
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Name:</label>
            <input type="text" class="form-control" id="recipient-name" #projectName>
          </div>
          <div class="form-group">
            <label for="message-text" class="col-form-label">Beschreibung:</label>
            <textarea class="form-control" id="message-text" #projectDescription></textarea>
          </div>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Farbe:</label>
            <div class="form-inline">
              <button type="button" class="btn btn-primary dropdown-toggle mr-2" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                Farben
              </button>
              <div class="dropdown-menu" id="dropdown">
                <button class="dropdown-item" *ngFor="let color of colors" [style]="'background: ' + color"
                        (click)="projectColor.value = color">{{ color }}</button>
              </div>
              <input type="color" class="form-control w-25" id="farbe" #projectColor>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal"
                  (click)="createProject(projectDescription.value, projectName.value, projectColor.value); createProjectForm.reset()">
            Projekt erstellen
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" *ngFor="let project of projects;" [id]="'projectEdit' + project.id" tabindex="-1" role="dialog"
     [attr.aria-labelledby]="'projectEditLabel' + project.id" aria-hidden="true">
  <div class="modal-dialog" role="document" *ngIf="data">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" [id]="'projectEditLabel' + project.id">{{ project.name}} bearbeiten</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form>
        <div class="modal-body">
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Name:</label>
            <input type="text" class="form-control" #projectEditName [value]="project.name">
          </div>
          <div class="form-group">
            <label for="message-text" class="col-form-label">Beschreibung:</label>
            <textarea class="form-control" #projectEditDescription [value]="project.description"></textarea>
          </div>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Farbe:</label>
            <div class="form-inline">
              <button type="button" class="btn btn-primary dropdown-toggle mr-2" id="dropdown-color-edit" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Farben
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdown-color-edit">
                <button class="dropdown-item" *ngFor="let color of colors" [style]="'background: ' + color"
                        (click)="projectEditColor.value = color">{{ color }}</button>
              </div>
              <input type="color" class="form-control w-25" id="projectEditColor" #projectEditColor
                     [value]="project.color">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="removeProject(project.id)">Projekt
            löschen
          </button>
          <button type="button" class="btn btn-primary" data-dismiss="modal"
                  (click)="editProject(project.id, projectEditDescription.value, projectEditName.value, projectEditColor.value)">
            Änderungen speichern
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<div *ngIf="data">
  <div *ngFor="let day of data.days; let dayIndex = index">
    <div class="modal fade" *ngFor="let worker of day.workers;" [id]="'info' + worker.id  + day.name" tabindex="-1"
         role="dialog" [attr.aria-labelledby]="'infoLabel' + worker.id  + day.name" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" [id]="'infoLabel' + worker.id  + day.name">{{ day.name }} {{ worker.name }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form>
            <div class="modal-body">
              <div class="form-group">
                <label for="message-text" class="col-form-label">Info:</label>
                <textarea class="form-control" #info [value]="worker.info"></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal"
                      (click)="setInfo(day, worker.id, dayIndex, info.value)">Änderungen speichern
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>

</div>
