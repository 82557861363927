import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import {AppRouterModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import { MobileComponent } from './mobile/mobile.component';
import { CallbackComponent } from './callback/callback.component';
import { WorkerSettingsComponent } from './worker-settings/worker-settings.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    SchedulerComponent,
    MobileComponent,
    CallbackComponent,
    WorkerSettingsComponent
  ],
    imports: [
        BrowserModule,
        AppRouterModule,
        HttpClientModule,
        FormsModule,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
