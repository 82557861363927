import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import {SchedulerComponent} from './scheduler/scheduler.component';
import {MobileComponent} from './mobile/mobile.component';
import {CallbackComponent} from './callback/callback.component';
import {WorkerSettingsComponent} from './worker-settings/worker-settings.component';

const routes: Routes = [
  { path: 'planner', component: SchedulerComponent},
  { path: 'mobile', component: MobileComponent},
  { path: 'callback', component: CallbackComponent},
  { path: 'einstellungen/arbeiter', component: WorkerSettingsComponent},
  { path: '**', redirectTo: '/planner'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRouterModule { }
