import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-worker-settings',
  templateUrl: './worker-settings.component.html',
  styleUrls: ['./worker-settings.component.css']
})
export class WorkerSettingsComponent implements OnInit {

  workers;
  saved;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.loadWorkers();
  }

  loadWorkers(): void {
    this.api.getWorkers().subscribe((data) => {
      this.workers = data;
    });
  }

  setWorkers(): void {
    this.api.setWorkers(this.workers).subscribe((data) => {
      this.workers = data;
      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 2000);
    });
  }

  deleteWorker(id): void {
    this.api.deleteWorker(id).subscribe((data) => {
      this.workers = data;
      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 2000);
    });
  }
}
