import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url = 'api/';
  ngToken;

  constructor(private http: HttpClient) {
  }

  getProjects(): any {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getCookie('ng_token'));
    return this.http.get(this.url + 'admin/projects', {
      headers
    });
  }

  createProject(description, name, color): any {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.getCookie('ng_token'))
      .set('Content-Type', 'application/json');
    return this.http.post(this.url + 'admin/project/create', JSON.stringify({description, name, color}), {
      headers
    });
  }

  editProject(id, description, name, color): any {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.getCookie('ng_token'))
      .set('Content-Type', 'application/json');
    return this.http.post(this.url + 'admin/project/edit', JSON.stringify({id, description, name, color}), {
      headers
    });
  }

  removeProject(id): any {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.getCookie('ng_token'))
      .set('Content-Type', 'application/json');
    return this.http.post(this.url + 'admin/project/remove', JSON.stringify({id}), {
      headers
    });
  }

  getTasks(kw, year): any {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.getCookie('ng_token'))
      .set('Content-Type', 'application/json');
    return this.http.post(this.url + 'admin/tasks', JSON.stringify({kw, year}), {
      headers
    });
  }

  getWorkers(): any {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.getCookie('ng_token'))
      .set('Content-Type', 'application/json');
    return this.http.get(this.url + 'admin/workers', {
      headers
    });
  }

  setWorkers(workers): any {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.getCookie('ng_token'))
      .set('Content-Type', 'application/json');
    return this.http.post(this.url + 'admin/worker/set', workers, {
      headers
    });
  }

  deleteWorker(id): any {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.getCookie('ng_token'))
      .set('Content-Type', 'application/json');
    return this.http.post(this.url + 'admin/worker/remove', { id }, {
      headers
    });
  }

  getMobileTasks(kw, year, day, worker): any {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.getCookie('ng_token'))
      .set('Content-Type', 'application/json');
    return this.http.post(this.url + 'mobile/tasks', JSON.stringify({year, kw, day, worker}), {
      headers
    });
  }

  setTasks(data): any {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.getCookie('ng_token'))
      .set('Content-Type', 'application/json');
    return this.http.post(this.url + 'admin/task/set', JSON.stringify(data), {
      headers
    });
  }

  setInfo(data): any {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.getCookie('ng_token'))
      .set('Content-Type', 'application/json');
    return this.http.post(this.url + 'admin/info/set', JSON.stringify(data), {
      headers
    });
  }

  login(code): any {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(this.url + 'login', JSON.stringify({code}), {
      headers
    });
  }

  getLogin(): any {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getCookie('ng_token'));
    return this.http.get(this.url + 'me', {
      headers
    });
  }

  getCookie(name): any {
    if (this.ngToken) {
      return this.ngToken;
    }
    const parts = document.cookie.split('; ');
    let result;
    for (const part of parts) {
      if (part.split('=')[0] === name) {
        result = part.split('=')[1];
      }
    }
    if (result !== undefined) {
      return result;
    } else {
      return false;
    }
  }
}
