import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  constructor(private api: ApiService, private route: ActivatedRoute, private app: AppComponent, private router: Router) {
    if (this.route.snapshot.queryParams.code) {
      this.api.login(this.route.snapshot.queryParams.code).subscribe((data: {
        status: string, user: any, id: string
      }) => {
        if (data.status === 'done') {
          this.app.user = data.user;
          this.setCookie('ng_token', data.user.id);
          if (this.app.user.role === 'admin') {
            this.router.navigate(['/planner']);
          } else if (this.app.user.role === 'worker') {
            this.router.navigate(['/mobile']);
          }
        }
      });
    }
  }

  setCookie(name, value, days = 30): void {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    this.api.ngToken = value;
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/; SameSite=Strict';
  }

  ngOnInit(): void {
  }

}
