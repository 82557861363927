import {Component, HostListener, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
import {AppComponent} from '../app.component';
import {Router} from '@angular/router';
import {stringify} from '@angular/compiler/src/util';

interface InsertTaskObject {
  id?: string;
  year?: number;
  kw: number;
  day: string;
  worker: string;
  time: string;
  project: number;
}

interface PlannerObject {
  days: Array<{
    name: string
    head: Array<string>,
    workers: Array<{
      id: number,
      name: string,
      tasks: Array<{
        length: Array<string>,
        projectName: string,
        projectId: number
        projectColor: string
      }>
      info: string,
    }>
  }>;
}

interface InsertInfoObject {
  id?: string;
  year: number;
  kw: number;
  day: string;
  worker: string;
  data: string;
}

interface CtrlMarkObject {
  pressed: boolean;
  kw?: number;
  day?: {
    name: string
    head: Array<string>,
    workers: Array<{
      id: number,
      name: string,
      tasks: Array<{
        length: Array<string>,
        projectName: string,
        projectId: number
        projectColor: string
      }>
      info: string,
    }>
  };
  dayIndex?: number;
  worker?: string;
  startTime?: string;
  endTime?: string;
  project: number;
}

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css']
})

export class SchedulerComponent implements OnInit {

  week: number;
  year: number;
  weekString: string;
  date: string;
  data: PlannerObject;
  projects;
  selectedProject: false | number = false;
  colors = ['#5c90b7',
    '#ca5e57',
    '#dab39e',
    '#6da775',
    '#d6b35c',
    '#a9664c',
    '#b783a9',
    '#f7cac9',
    '#a5cdce',
    '#998674'];
  showMode = false;
  collapseProjects = true;
  ctrlMark: (CtrlMarkObject | false);

  constructor(private app: AppComponent, private api: ApiService, private router: Router) {
    this.api.getLogin().subscribe((data: { user: string, link: string, role: string }) => {
      if (data.user) {
        this.app.user = {user: data.user, role: data.role};
        if (this.app.user.role === 'worker') {
          this.router.navigate(['/mobile']);
        }
      } else {
        window.location.href = data.link;
      }
    });
  }

  ngOnInit(): void {
    this.week = this.getWeek();
    this.loadProjects();
    this.loadTasks();
    setInterval(() => {
      const elements = document.getElementsByClassName('modal');
      for (const index of Object.keys(elements)) {
        if (window.getComputedStyle(elements[index]).display !== 'none') {
          return;
        }
      }
      if (!document.hidden) {
        this.loadProjects();
        this.loadTasks();
      }
    }, 5000);
  }

  loadProjects(): void {
    this.api.getProjects().subscribe((data) => {
      let found = false;
      for (const element of data) {
        if (element.id === this.selectedProject) {
          element.selected = true;
          found = true;
        }
      }
      if (!found && this.selectedProject !== 0) {
        this.selectedProject = false;
      }
      this.projects = data;
    });
  }

  loadTasks(): void {
    this.getWeekDates(this.week);
    this.api.getTasks(this.week, this.year).subscribe((data) => {
      this.data = data.tasks;
    });
  }

  setTask(day, worker, dayIndex, time): void {
    if (typeof this.selectedProject === 'number') {
      if (this.ctrlMark && this.ctrlMark.endTime === undefined && this.ctrlMark.startTime === undefined) {
        this.ctrlMark.day = day;
        this.ctrlMark.kw = this.week;
        this.ctrlMark.dayIndex = dayIndex;
        this.ctrlMark.startTime = time;
        this.ctrlMark.worker = worker;
        return;
      } else if (this.ctrlMark && !this.ctrlMark.endTime && this.ctrlMark.startTime) {
        this.ctrlMark.endTime = time;
        document.getElementsByTagName('body')[0].style.cursor = 'wait';
        const valData = [];
        let valStart: (false| string) = false;
        let valEnd: (false| string) = false;
        for (const valTime of this.data.days[dayIndex].head) {
          if (valTime === this.ctrlMark.startTime) {
            valStart = valTime;
          }
          if (valStart && !valEnd) {
            valData.push({
              kw: this.week,
              day: this.ctrlMark.day.name,
              worker: this.ctrlMark.worker,
              time: valTime,
              year: this.year,
              project: this.ctrlMark.project
            });
          }
          if (valTime === this.ctrlMark.endTime) {
            valEnd = valTime;
          }
        }
        this.ctrlMark = false;
        this.api.setTasks(valData).subscribe((taskData) => {
          this.data = taskData.tasks;
          document.getElementsByTagName('body')[0].style.cursor = 'default';
        });
        return;
      }
      const data: InsertTaskObject = {
        kw: this.week,
        day: day.name,
        worker,
        time,
        year: this.year,
        project: this.selectedProject
      };
      this.api.setTasks([data]).subscribe((taskData) => {
        this.data = taskData.tasks;
      });
    }
  }

  setTasks(day, worker, dayIndex): void {
    if (typeof this.selectedProject === 'number') {
      document.getElementsByTagName('body')[0].style.cursor = 'wait';
      const data = [];
      for (const time of this.data.days[dayIndex].head) {
        data.push({
          kw: this.week,
          day: day.name,
          worker,
          time,
          year: this.year,
          project: this.selectedProject
        });
      }
      this.api.setTasks(data).subscribe((taskData) => {
        this.data = taskData.tasks;
        document.getElementsByTagName('body')[0].style.cursor = 'default';
      });
    }
  }


  setInfo(day, worker, dayIndex, value): void {
    const data: InsertInfoObject = {
      kw: this.week,
      day: day.name,
      worker,
      year: this.year,
      data: value
    };
    this.api.setInfo(data).subscribe((taskData) => {
      this.data = taskData.tasks;
    });
  }

  selectProject(i): void {
    if (i === 0 && this.selectedProject === 0) {
      this.selectedProject = false;
      return;
    }
    if (i === 0) {
      this.selectedProject = 0;
      return;
    }
    if (!this.projects[i - 1].selected) {
      for (const element of this.projects) {
        element.selected = false;
      }
      this.projects[i - 1].selected = true;
      this.selectedProject = this.projects[i - 1].id;
    } else {
      this.projects[i - 1].selected = false;
      this.selectedProject = false;
    }
  }

  createProject(description, name, color): void {
    this.api.createProject(description, name, color).subscribe((data) => {
      this.projects = data.projects;
    });
  }

  editProject(id, description, name, color): void {
    this.api.editProject(id, description, name, color).subscribe((data) => {
      this.projects = data.projects;
    });
  }

  removeProject(id): void {
    this.api.removeProject(id).subscribe((data) => {
      this.projects = data.projects;
    });
  }

  getWeek(date: (Date | false) = false): number {
    const today = (date) ? date : new Date();
    this.year = today.getFullYear();
    const firstDayOfYear = this.getFirstDay(today.getFullYear());
    const pastDaysOfYear = Math.floor((today.getTime() - firstDayOfYear.getTime()) / 86400000);
    return Math.ceil((pastDaysOfYear + (firstDayOfYear.getDay() + 1)) / 7);
  }

  getWeekDates(kw): void {
    if (kw < 1) {
      const oldYearFirstDay = this.getFirstDay(this.year);
      oldYearFirstDay.setDate(oldYearFirstDay.getDate() - 7);
      this.week = this.getWeek(oldYearFirstDay);
      kw = this.week;
    }
    const start = this.getFirstDay(this.year);
    console.log(start.toLocaleString('de-DE', {weekday: 'short', month: 'short', day: 'numeric', year: 'numeric'}));
    start.setDate(start.getDate() + ((kw - 1) * 7));
    console.log(start.toLocaleString('de-DE', {weekday: 'short', month: 'short', day: 'numeric', year: 'numeric'}));
    const end = new Date(start.getFullYear(), start.getMonth());
    end.setDate(start.getDate() + 5);
    this.weekString = start.toLocaleString('de-DE', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    }) + ' - ' + end.toLocaleString('de-DE', {weekday: 'short', month: 'short', day: 'numeric', year: 'numeric'});
    const nextYear = this.getFirstDay(this.year + 1);
    if (start.getDate() === nextYear.getDate() && start.getMonth() === nextYear.getMonth() && start.getFullYear() === this.year + 1) {
      this.year = nextYear.getFullYear();
      this.week = 1;
    }
  }

  getFirstDay(year): Date {
    const date = new Date(year, 0, 1);
    for (let i = 0; i < 7; i++) {
      if (date.getDay() === 4) {
        date.setDate(date.getDate() - 3);
        return date;
      }
      date.setDate(date.getDate() + 1);
    }
  }

  @HostListener('window:keydown', ['$event']) handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'ControlLeft') {
      if (this.selectedProject && !this.ctrlMark) {
        this.ctrlMark = {
          pressed: true,
          project: this.selectedProject
        };
      }
    }
  }

  @HostListener('window:keyup', ['$event']) handleKeyUp = (event: KeyboardEvent) => {
    if (event.code === 'ControlLeft') {
      this.ctrlMark = false;
    }
  }
}
